import { AccountStatus, Auth } from '../../auth-provider';

/**
 * Render a banner that automatically displays when:
 * 1. User is currently in a free trial period [success]
 * 2. User has an inactive or cancelled subscription [danger]
 * 3. User is past due, and needs to fix their payment method [warning]
 */
export default function SubscsriptionBanner(props: { auth: Auth}) {
  if (!props.auth) return null; // No loading state, just stay hidden
  
  switch (props.auth.status) {
    case AccountStatus.trialing: {
      return <div className='text-center py-3 bg-success'>
        <h2 className='m-0'>You're currently on the free trial. <a href='/api/stripe/portal'>Manage billing details</a>.</h2>
      </div>;
    }
    case AccountStatus.pastDue: {
      return <div className='text-center py-3 bg-warning'>
        <h2 className='m-0'>Previous payment has failed. <a href='/api/stripe/portal'>Update payment details</a>.</h2>
      </div>;
    }
    case AccountStatus.inactive: {
      return <div className='text-center py-3 bg-danger'>
        <h2 className='m-0'>Filtering has been paused. <a href='/api/stripe/checkout'>Reactivate your account</a>.</h2>
      </div>;
    }
    default: return null;
  };
}
