import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function PolicyRefund() {
  return <Container className='mt-1'>
    <Row>
      <Col>
        <h1>Refund Policy</h1>
        <p><strong>Last updated:  September 24, 2023</strong></p>
        <hr />
        <h2>14-Day Money-Back Guarantee</h2>
        <p>If you decide to cancel your subscription to Unspam.io within the first 14 days of your purchase, you are eligible for a full refund, no questions asked.</p>
        <p>To request a refund, please contact our customer support team at <a href="mailto:hi@unspam.io"><strong>hi@unspam.io</strong></a> with your Account details and the reason for cancellation. Our team will process your refund promptly upon receipt of your request.</p>
        <p>Please note that the 14-Day Money-Back Guarantee only applies to new customers and cannot be used for renewals or upgrades of existing subscriptions.</p>

        <h2>Refunds after the 14-Day Money-Back Guarantee Period</h2>
        <p>If you wish to cancel your subscription after the 14-day money-back guarantee period, please note that we do not offer pro-rated refunds for unused portions of your subscription. However, your subscription will remain active until the end of the billing cycle, and you can continue to use the Service until that time.</p>

        <h2>Contact Information</h2>
        <p>If you have any questions or concerns about our Refund Policy, please do not hesitate to contact us at <a href="mailto:hi@unspam.io"><strong>hi@unspam.io</strong></a>. We are always here to help and ensure a smooth experience with our service.</p>
        <hr />
      </Col>
    </Row>
  </Container>
}

export default PolicyRefund;
