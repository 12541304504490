import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

function LandingPage() {
  return <div className='landing-page'>
    <Container className='section-hero' fluid>
      <Row className='text-center py-lg-big'>
        <Col>
          <h1>Free up your inbox <br className="d-none d-md-inline"/>from unsolicited cold emails</h1>
          <h3 className='my-3'>Regain control of your inbox by effortlessly filtering out <br className="d-none d-md-inline"/>recruitment, outsourced software, and leadgen services.</h3>
          <p className='mt-4'><Button href='/signup' size='lg'>Try Unspam for free</Button></p>
          <p className='text-muted small'><b>Free for 30 days, then $4.99/mo thereafter. Cancel at any time.</b></p>
        </Col>
      </Row>
    </Container>

    <Container fluid>
      <Container>
        <Row className='py-lg-big'>
          <Col sm={6}>
            <h2>Cut the noise,<br />regain sanity</h2>
          </Col>
          <Col>
            <p><strong>Simplify your inbox in four easy steps</strong></p>
            <ol>
              <li>Connect your Gmail or Google Workspace account</li>
              <li>Let our trained AI scan new emails for unsolicited messages</li>
              <li>Spam emails are labeled or archived automatically</li>
              <li>Experience a clutter-free inbox and focus on what truly matters</li>
            </ol>
            <p><a href='/signup'>→ Give it a try!</a></p>
          </Col>
        </Row>
      </Container>
    </Container>

    <Container fluid className='section-callout'>
      <Row className='py-lg-big text-center'>
        <Col>
          <h2>Founders, CEOs & engineering leaders<br />use Unspam to cut the noise in their inbox</h2>
        </Col>
      </Row>
    </Container>

    <Container>
      <Row className='py-lg-big'>
        <Col>
          <h2>Private & secure, by design</h2>
          <p className='mt-5'><strong>Built with security as the #1 priority</strong></p>
          <ul>
            <li>Your emails are <strong>never stored</strong> and <strong>never logged</strong>, only ever living momentarily in memory.</li>
            <li>Your emails are <strong><i>not</i> used to train the AI filter</strong>, unless you forward it to Unspam for training.</li>
            <li>Your emails are solely used to identify spam emails, and nothing else.</li>
            <li>You can opt-in to use your own Open AI account for filtering.</li>
          </ul>
          <p><a href='/privacy-policy'>→ Read the privacy policy</a></p>
        </Col>
      </Row>
    </Container>

    <Container className='section-closing' fluid>
      <Container>
        <Row className='py-lg-big'>
          <Col md={12} className='order-1'>
            <h2>Why trust Unspam?</h2>
          </Col>
          <Col md={8} className='order-3 order-md-2'>

            <p className='mt-5'>Hi, I'm <a href='https://louwhopley.com?ref=unspam.io'>Louw</a>, founder of enterprise fintech scale-up, <a href='https://rootplatform.com?ref=unspam.io'>Root</a>. Like you, my inbox was flooded with unsolicited emails, a chaos that seemed to amplify since early 2023. These distractions weren’t just annoying; they were stealing precious time away from our mission.</p>
            <p>So, I created Unspam. Initially, it was a simple tool for myself and fellow founders to regain control of our inboxes. And it worked — about 10% of my emails were swiftly and accurately categorized as unsolicited messages.</p>
            <p>Given its success, I've decided to share Unspam with a broader audience, evolving it into Unspam.io. I value privacy intensely, a principle shared by my fellow founder and CEO peers who also use Unspam. Rest assured, the goal here isn’t to read or record your emails — it's about empowering you to focus on what truly matters by minimizing email distractions.</p>

            <p className='mt-5'><Button href='/signup' size='lg'>Experience a cleaner inbox</Button></p>
          </Col>
          <Col md={4} className='image order-2 order-md-3'>
            <img className='mt-5' src='/unspam-founder-louw-hopley.png' alt='Unspam is trusted and secure' />
          </Col>
        </Row>
      </Container>
    </Container>
  </div>;
}

export default LandingPage;
