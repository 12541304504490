import React, { useState } from 'react';
import { Modal, Button, Container } from 'react-bootstrap';
import { fetchSelfAPI } from '../../utils/https';
import { Link } from 'react-router-dom';

export const DeleteAccountModal = () => {
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const handleDeleteAccountClose = () => setShowDeleteAccount(false);
  const [typedEmail, setTypedEmail] = useState('');

  const handleDangerouslyDeleteAccount = async () => {
    if (typedEmail === '') return alert('Please enter your email address to confirm account deletion.');
    try {
      const result = await fetchSelfAPI('/api/delete-account', { method: 'post', body: JSON.stringify({ typedEmail }) });
      if (result.error) return alert(`Error: Could not delete account. Please try again.\n\n${JSON.stringify(result.error)}`);
      window.location.href = '/?notice=account_deleted';
    } catch (error) {
      alert(`Error: Could not delete account. Please try again.\n\n${JSON.stringify(error)}`);
      console.error('Error: Could not delete account.', error);
    }
  };

  return (
    <Container>
      <div className=' text-center'>
        <Link to='#' className='text-danger' onClick={() => setShowDeleteAccount(true)}>Permanently delete my account.</Link>
      </div>

      <Modal show={showDeleteAccount} onHide={handleDeleteAccountClose} backdrop='static' centered>
        <Modal.Body className='text-dark'>
          <h1>Are you sure?</h1>
          <hr />
          <p>This cannot be undone. Your Stripe subscription will automatically be cancelled. After this action, Unspam will have no recollection of who you are and will not be able to provide support.</p>
          <p>Enter your email address to confirm account deletion.</p>
          <input type='email' className='form-control bg-light text-dark' placeholder='Email address' onChange={e => setTypedEmail(e.target.value)} />
          <br />
          <div className='d-grid gap-2'>
            <Button variant='success' className='text-white' onClick={handleDeleteAccountClose}>
              Abort
            </Button>
            <Button variant='danger' className='text-white' onClick={handleDangerouslyDeleteAccount}>
              Delete my account
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default DeleteAccountModal;
