import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function PolicyPrivacy() {
  return <Container className='mt-1'>
    <Row>
      <Col>
        <h1>Privacy Policy</h1>
        <p><strong>Last updated:  October 30, 2023</strong></p>
        <hr />

        <h2>Information We Collect</h2>
        <p>We collect minimal information necessary for our service to function, such as the customer's email address and information regarding their email provider in order to receive webhooks about new emails and to update those emails based on the filtering rules. We do not collect any sensitive information, such as financial information or social security numbers, and email content is only used temporarily and never stored. However, <a href="https://stripe.com/">Stripe</a> is used to collect payment information for billing purposes.</p>

        <h2>Use of Information</h2>
        <p>We use the information we collect to provide our email filtering service and to communicate directly with our customers. We may also use the information to improve our service, to develop new products or services, and to perform data analysis.</p>
        <p><strong>We do not store or use customer emails</strong>, neither to train or fine-tune the classification AI model, unless the customer deliberately forwards an email to <a href="mailto:train@unspam.io">train@unspam.io</a>. Emails forwarded for training will be stripped of any Personal Identifiable Information contained therein.</p>

        <p><strong>AI Processing:</strong> Emails are shared with OpenAI for analysis to identify unsolicited content, with user consent. We ensure that these third-party providers comply with data protection regulations.</p>

        <p>The use and transfer to any other app of information received from Google APIs will adhere to the <a href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes'>Google API Services User Data Policy</a>, including the Limited Use requirements.</p>

        <h2>Disclosure of Information</h2>
        <p>We do not sell or share our customer's information for any other purposes. However, we do use third-party providers to process and store information as follows:</p>
        <ul>
          <li><strong>Stripe:</strong> Storing customer information required for billing purposes.</li>
          <li><strong>Cloudflare:</strong> The application is hosted on Cloudflare’s serverless cloud infrastructure, and is used for processing customers’ emails for filtering. Cloudflare processes Personal Identifiable Information information, and does not store or log any information other than the customer's name, email address, and profile picture URL as received from Google during login.</li>
          <li><strong>OpenAI:</strong> Used for reviewing and classifying emails with AI. Users’ email contents are shared with OpenAI for the purpose of identifying unsolicited messages, and is confirmed with explicit user consent during sign up. <u>OpenAI is configured not to use Unspam data for evolving their AI models</u> (<a href="https://openai.com/policies/api-data-usage-policies">read more</a>).</li>
        </ul>
        <p>We ensure that these third-party providers are compliant with data protection regulations and are committed to protecting our customer's information.</p>

        <h2>Security</h2>
        <p>We take reasonable measures to protect the information we collect from unauthorized access, use, or disclosure. We follow a zero-trust design approach, and actively attempt to store are little information as possible. However, no transmission of data over the internet or electronic storage system can be guaranteed to be 100% secure.</p>

        <h2>Retention of Information</h2>
        <p>We retain customer information for as long as necessary to provide our service or as required by law. Once the information is no longer necessary, we securely dispose of it.</p>

        <h2>Your Rights</h2>
        <p>Customers have the right to access, correct, or delete their personal information. Customers can contact us directly to exercise their rights.</p>

        <h2>Changes to this Privacy Policy</h2>
        <p>We may update this privacy policy from time to time. Customers will be notified of any significant changes to this policy.</p>

        <p>If you have any questions or concerns about our privacy policy or the way we handle your personal information, please contact us at <a href="mailto:hi@unspam.io">hi@unspam.io</a>.</p>
        <hr />
      </Col>
    </Row>
  </Container>;
}

export default PolicyPrivacy;
