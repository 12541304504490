import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import GoogleLoginButton from '../layouts/utils/google-login-button';
import { AuthContext } from '../auth-provider';
import { Link, Navigate } from 'react-router-dom';

function LoginPage() {
  const auth = useContext(AuthContext)!;
  if (auth) return <Navigate to='/account' />;

  return <div style={{ width:'500px', maxWidth: '100%' }}>
    <Card className='text-center mx-2' style={{ padding: '2rem' }}>
      <h1>Welcome back</h1>
      <p className='pb-3'></p>
      <GoogleLoginButton href={'/api/login'} />
    </Card>
    <p className='text-center pt-4'>Don't have an account? <Link to='/signup'>Sign Up</Link></p>
  </div>;
}

export default LoginPage;
