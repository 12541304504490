import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import GoogleLoginButton from '../layouts/utils/google-login-button';
import { AuthContext } from '../auth-provider';
import { Navigate } from 'react-router-dom';

function CorrectGooglePermissionsRequiredPage() {
  const auth = useContext(AuthContext)!;

  if (auth && auth.googleEmailPermissionReceived) return <Navigate to='/account' />;

  return <div style={{ width:'500px', maxWidth: '100%' }}>
    <Card className='text-center mx-2' style={{ padding: '2rem' }}>
      <h1>Unspam Needs Access to Your Email</h1>
      <p className='pt-4'>It looks like you've signed up for Unspam without granting email access. This access is essential for Unspam to function effectively and serve its core purpose.</p>
      <p>Your emails will not be stored, recorded, or used for AI model training. For more details, please review our <a href='/privacy-policy'>Privacy Policy</a>.</p>
      <p className='pb-4'>To continue using Unspam, please sign in again and grant the necessary email access permissions.</p>
      <GoogleLoginButton href={'/api/login'} />
    </Card>
  </div>;
}

export default CorrectGooglePermissionsRequiredPage;
