
const styles = {
  container: {
    margin: '1em 0',
    textAlign: "center",
  } as React.CSSProperties,
  link: {
    color: 'inherit',
  } as React.CSSProperties,
};

export default function Footer() {
  return <div style={styles.container} className='text-secondary'>
    {/* <a style={styles.link} href='/faq'>FAQ</a>
    &nbsp;&nbsp;•&nbsp;&nbsp; */}
    <a style={styles.link} href='/blog'>Blog</a>
    &nbsp;&nbsp;•&nbsp;&nbsp;
    <a style={styles.link} href='/terms-of-use'>Terms of Use</a>
    &nbsp;&nbsp;•&nbsp;&nbsp;
    <a style={styles.link} href='/privacy-policy'>Privacy Policy</a>
    &nbsp;&nbsp;•&nbsp;&nbsp;
    <a style={styles.link} href='/refund-policy'>Refund Policy</a>
    <br />
    <span>Unspam.io &copy; 2024</span>
  </div>;
}
