import { Outlet } from 'react-router-dom';
import Footer from './utils/footer';
import { Container, Nav, Navbar } from 'react-bootstrap';

export default function LandingLayout() {
  return <>
    <Container>
      <Navbar collapseOnSelect expand='md' bg='transparent' variant='dark'>
        <Navbar.Brand href='/account'>
          <h1>Unspam.io</h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className="ms-auto justify-content-end">
            <Nav.Link href={'/blog'}>Blog</Nav.Link>
            <Nav.Link href={'/login'}>Login</Nav.Link>
            <Nav.Link href={'/signup'}><strong>Sign up</strong></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container >

    <Outlet />
    <Footer />
  </>;
}
