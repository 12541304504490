import React, { useContext, useEffect, useState } from 'react';
import { Card, Table, Modal, Button, Badge, Form, Col, Row } from 'react-bootstrap';
import { AuthContext } from '../../auth-provider';
import { fetchSelfAPI } from '../../utils/https';
import { useSearchParams } from 'react-router-dom';
import DeleteAccountModal from '../../layouts/utils/delete-account-modal';
import WhitelistCard from './whitelist-card';
import RecentlyFilteredDomains from './recently-filtered-domains';

function AccountPage() {
  const auth = useContext(AuthContext)!;
  const [searchParams,] = useSearchParams();
  const welcome = searchParams.get('welcome') === 'true';
  const [uiSpamFilterStatus, setUiSpamFilterStatus] = useState(auth.spamFilterStatus);
  const [openAIKeySummary, setOpenAIKeySummary] = useState(auth.openAIKeySummary);
  const [stats, setStats] = useState<{
    emailsProcessed: number,
    markedAsSpam: number,
    developmentServices: number,
    recruitmentServices: number,
    leadgenServices: number,
    productivityServices: number,
    markedAsSpamByGoogle: number,
  } | null>(null);
  const [lastFilteredDomains, setLastFilteredDomains] = useState<string[]>([]);

  // Modal
  const [show, setShow] = useState(welcome);
  const handleClose = () => setShow(false);

  useEffect(() => {
    // Fetch stats
    const fetchStats = async () => {
      try {
        const results = await fetchSelfAPI('/api/web/account-stats');
        setStats(results.stats);
        setLastFilteredDomains(results.lastFilteredDomains);
      } catch (error) {
        console.error('Error: Could not fetch account stats.', error);
        alert('Error: Could not fetch account stats.');
      }
    };
    fetchStats();
  }, []);

  const handleSpamFilterStatusChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setUiSpamFilterStatus(value);
    try {
      await fetchSelfAPI('/api/web/spam-filter-status', {
        method: 'POST',
        body: JSON.stringify({ spamFilterStatus: value }),
      });
      console.log('Spam filter updated to:', value);
    } catch (error) {
      console.error('Error: Could not update spam filter status.', error);
      alert('Error: Could not update spam filter status.');
      setUiSpamFilterStatus(auth.spamFilterStatus);
    }
  };

  const handleOpenAIKeySaving = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget as HTMLFormElement;
    const key = form.querySelector('input')!.value;
    if (key.slice(0, 3) !== 'sk-' || key.length < 40) { // Porbably !== 51
      alert('Please enter a valid OpenAI key.');
      return;
    }
    try {
      const { openAIKeySummary } = await fetchSelfAPI('/api/web/openai-key', {
        method: 'POST',
        body: JSON.stringify({ key, method: 'POST' }),
      });
      console.log('OpenAI key saved:', openAIKeySummary);
      setOpenAIKeySummary(openAIKeySummary);
    } catch (error) {
      console.error('Error: Could not save OpenAI key.', error);
      alert('Error: Could not save OpenAI key.');
    }
  };

  const handleOpenAIKeyRemoval = async () => {
    try {
      await fetchSelfAPI('/api/web/openai-key', {
        method: 'POST',
        body: JSON.stringify({ method: 'DELETE' }),
      });
      setOpenAIKeySummary(null);
      console.log('OpenAI key removed');

    } catch (error) {
      console.error('Error: Could not remove OpenAI key.', error);
      alert('Error: Could not remove OpenAI key.');
    }
  };


  const renderStats = () => {
    return <>
      <Row className='mb-4 text-center'>
        <Col>
          <Card style={{ height: '100%' }}>
            <Card.Body>
              <h1>{stats!.emailsProcessed || '-'}</h1>
              Emails processed by Unspam
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{ height: '100%' }}>
            <Card.Body>
              <h1>{stats!.markedAsSpam || '-'}</h1>
              Marked spam by Unspam
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{ height: '100%' }}>
            <Card.Body>
              <h1>{Math.round(stats!.markedAsSpam / ((stats!.emailsProcessed - stats!.markedAsSpamByGoogle) || 1) * 100) / 10} %</h1>
              Filtered out by Unspam<br />
              <small>excluding marked as spam by Google</small>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  };

  const renderSettings = () => {
    return <>
      <Card className='mb-4'>
        <Card.Header>
          <h3 style={{ margin: 0 }}>Settings</h3>
        </Card.Header>
        <Card.Body>
          <h3>Spam Filter Status</h3>
          <p>Configure how Unspam should treat emails matched with the AI classifier.</p>
          <Form>
            <Form.Group className='mb-3'>
              <Form.Check
                type='radio'
                id='labelAndArchive'
                label='Label matched emails & archive them'
                name='spamFilterStatus'
                value='archive'
                checked={uiSpamFilterStatus === 'archive'}
                onChange={handleSpamFilterStatusChange}
              />
              <Form.Check
                type='radio'
                id='labelOnly'
                label='Only label matched emails, keep them in the inbox'
                name='spamFilterStatus'
                value='label-only'
                checked={uiSpamFilterStatus === 'label-only'}
                onChange={handleSpamFilterStatusChange}
              />
              <Form.Check
                type='radio'
                id='paused'
                label='Pause the filtering'
                name='spamFilterStatus'
                value='paused'
                checked={uiSpamFilterStatus === 'paused'}
                onChange={handleSpamFilterStatusChange}
                className='text-secondary'
              />
            </Form.Group>
          </Form>

          <div className='mb-4'>
            <h3>Self-Managed OpenAI Account</h3>
            <p>Optionally use your own OpenAI account for the email filter prompting process. This ensures that you have more control over where your emails are processes. Usage limits (token limites) are also removed if processed on your own account.</p>
            <p>Keys are store encrypted and only used for email classification on your account.</p>

            {!openAIKeySummary ?
              <Form onSubmit={handleOpenAIKeySaving}>
                <Form.Group className="mb-3" controlId="openAIKey.KeyInput">
                  <Form.Label>OpenAI API Key</Form.Label>
                  <Form.Control type="text" placeholder="Enter your key here ('sk-r3TR...5aQw')" />
                </Form.Group>
                <Button variant="primary" size="sm" type="submit">Save key</Button>
              </Form> :
              <Form>
                <Form.Label>OpenAI API Key</Form.Label>
                <p><i>You have key <code>{openAIKeySummary}</code> saved. Remove this key to fall back to Unspam's OpenAI account and re-apply usage limits.</i></p>
                <Button variant="outline-danger" size="sm" onClick={handleOpenAIKeyRemoval}>Remove key</Button>
              </Form>
            }
          </div>
        </Card.Body>
      </Card >
    </>
  };

  return (
    <>
      {stats && renderStats()}
      <RecentlyFilteredDomains domains={lastFilteredDomains} />
      <WhitelistCard />
      {renderSettings()}
      <Card className='mb-5'>
        <Card.Header>
          <h3 style={{ margin: 0 }}>Profile Overview</h3>
        </Card.Header>
        <Card.Body>
          <Table style={{ tableLayout: 'fixed' }} borderless>
            <tbody>
              <tr><td><b>Name</b></td><td>{auth.name}</td></tr>
              <tr><td><b>Email</b></td><td>{auth.email}</td></tr>
              <tr><td><b>Customer ID</b></td><td>{auth.customerId}</td></tr>
              <tr><td><b>Account Status</b></td><td>{auth.status}</td></tr>
              <tr>
                <td colSpan={2}>
                  <b>Filters</b>
                  <ul>
                    <li>Software development outsource services</li>
                    <li>Recruitment and hiring services</li>
                    <li>Sales and lead generation services</li>
                    <li>Productivity and BPO services</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <p><b>How It Works</b></p>
                  <ul>
                    <li><b>Activation:</b> After activation, Unspam automatically screens your emails for unsolicited offers in the background.</li>
                    <li><b>Filtering:</b> Unwanted emails are flagged, labeled, and optionally archived based on your settings, decluttering your inbox.</li>
                    <li><b>Privacy:</b> Unspam discards each email's content after processing, ensuring privacy and security.</li>
                    <li><b>Result:</b> Enjoy a cleaner inbox with about 10% fewer unwanted emails, focusing on what's important.</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <DeleteAccountModal />

      <Modal show={show} onHide={handleClose} backdrop='static' centered>
        <Modal.Body className='text-dark'>
          <h1>You're all set! 🎉</h1>
          <hr />
          <p>Unspam has been set up and will now run in the background of your inbox.</p>
          <p>
            It will scan new emails using AI to see if they are unsolicited sales offerings. If they are detected, they will get labeled with one of the following labels and automatically archived:
          </p>
          <ul>
            <li><Badge bg='secondary'>unspam/development_services</Badge></li>
            <li><Badge bg='secondary'>unspam/recruitment_services</Badge></li>
            <li><Badge bg='secondary'>unspam/leadgen_services</Badge></li>
            <li><Badge bg='secondary'>unspam/productivity_services</Badge></li>
          </ul>
          <p>If an email is incorrectly filtered, please forward it to <a href='mailto:train@unspam.io'>train@unspam.io</a> for us to improve the filter.</p>
          <p>Lastly, please let us know your feedback through the button at the bottom right.</p>
          <p><i>To sanity, and beyond!</i></p>
          <br />
          <div className='d-grid gap-2'>
            <Button variant='primary' onClick={handleClose}>
              Great, now let's get back to life
            </Button>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
}

export default AccountPage;
