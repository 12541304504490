export const fetchSelfAPI = async (path: string, options?: any) => {
  const url = process.env.NODE_ENV === 'development' ? 'http://localhost:8787' : '';

  let opts = {...options};
  if (process.env.NODE_ENV === 'development')
    opts['credentials'] = 'include';

  const response = await fetch(url + path, opts);
  if (response.status !== 200) throw new Error(response.statusText);
  
  const data = await response.json() as any;
  return data;
};
