import React from 'react';
import { Placeholder } from 'react-bootstrap';


export default function Loading(props: { centerAlign?: boolean }) {

  const styles = {
    container: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: props.centerAlign ? 'center' : 'left',
    } as React.CSSProperties,
  };

  return <div style={styles.container}>
    {props.centerAlign ? <span>Loading...</span> : <>
      <p><Placeholder xs={6} /></p>
      <p><Placeholder className="w-75" /></p>
      <p><Placeholder style={{ width: '25%' }} /></p>
    </>}
  </div>;
}
