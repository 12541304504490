import { PopupButton } from '@typeform/embed-react'

const styles = {
  button: {
    position: 'fixed',
    right: '2rem',
    bottom: 0,
    background: 'rgba(255,255,255,0.8)',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    padding: '0.5em 1.2em',
    color: '#222',
    fontWeight: 'bold',
    cursor: 'pointer',
    border: 'none',
  } as any,
};

export default function Feedback(props: { customerId: number }) {
  return <PopupButton id='tih6MI5o' style={styles.button} hidden={{ customer_id: (props.customerId + '') }}>
    Give Feedback
  </PopupButton>;
}
