import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './styles/index.scss';
import { AuthProvider } from './auth-provider';

// Layouts
import SidebarLayout from './layouts/sidebar-layout';
import CardLayout from './layouts/card-layout';

// Pages
import AccountPage from './pages/account';
import LandingPage from './pages/landing';
import LoginPage from './pages/login';
import SamplesPage from './pages/samples';
import PolicyTermsOfUse from './pages/policy-terms-of-use';
import PolicyPrivacy from './pages/policy-privacy';
import PolicyRefund from './pages/policy-refund';
import LandingLayout from './layouts/landing-layout';
import SignupPage from './pages/signup';
import CorrectGooglePermissionsRequiredPage from './pages/correct-google-permissions-required';
import BlogPage from './pages/blog';
import BlogPostPage from './pages/blog-post';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>

      <Routes>
        <Route element={<LandingLayout />}>
          <Route path='/' element={<LandingPage />} />
          <Route path='/blog' element={<BlogPage />} />
          <Route path='/blog/:postId' element={<BlogPostPage />} />
          <Route path='/terms-of-use' element={<PolicyTermsOfUse />} />
          <Route path='/privacy-policy' element={<PolicyPrivacy />} />
          <Route path='/refund-policy' element={<PolicyRefund />} />
        </Route>
      </Routes>

      <AuthProvider>
        <Routes>

          <Route element={<CardLayout skipLoading hideNavMenu />}>
            <Route path='/login' element={<LoginPage />} /> {/* Go straight to account if logged in */}
            <Route path='/signup' element={<SignupPage />} /> {/* Go straight to account if logged in */}
            <Route path='/correct-google-permissions-required' element={<CorrectGooglePermissionsRequiredPage />} />
          </Route>

          {/* Logged-in pages */}
          <Route element={<SidebarLayout sidebarProps={{
            title: 'Menu',
            items: [
              { title: 'Account', navigateTo: `/account` },
              // { title: 'Samples', navigateTo: `/samples` },
              { title: 'Billing', navigateTo: `/api/stripe/portal` },
              { title: 'Logout', navigateTo: `/api/logout` },
            ]
          }} />} >
            <Route path='/account' element={<AccountPage />} />
            <Route path='/samples' element={<SamplesPage />} />
          </Route>

        </Routes>
      </AuthProvider>

    </BrowserRouter>
  </React.StrictMode>
);
