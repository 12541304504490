import { Col, Container, Row, Navbar, Nav } from 'react-bootstrap';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { SidebarProps } from './utils/sidebar';
import { useContext } from 'react';
import { Auth, AuthContext, AccountStatus } from '../auth-provider';
import Loading from './loading';
import Feedback from './utils/feedback';
import SubscsriptioNavbaranner from './utils/subscription-banner';
import Footer from './utils/footer';

export default function SidebarLayout(props: {
  sidebarProps: SidebarProps,
  navbarSideText?: string,
  navbarTitle?: string,
}) {
  // Determine user's authentication state
  const location = useLocation();
  const params = useParams();
  const title = pathToTitle(location, params);

  // Kick the user out if not authenticated
  const auth = useContext(AuthContext);
  if (auth === undefined) return <Loading centerAlign />
  if (auth === null) return <Navigate to='/' replace={true} />

  // Redirect the user if they still need to give full Google Email Permissions in Oauth
  if (auth && !auth.googleEmailPermissionReceived) return <Navigate to='/correct-google-permissions-required' replace={true} />

  // Redirect the user if they still need to complete payment signup
  if (auth && auth.status === AccountStatus.signUpIncomplete) {
    window.location.href = '/api/stripe/checkout';
    return <></>;
  }

  return <>
    <SubscsriptioNavbaranner auth={auth as Auth} />
    <Container>
      <Navbar collapseOnSelect expand='md' bg='transparent' variant='dark'>
        <Navbar.Brand href='/account'>
          <h1>Unspam.io</h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav'>
          <Nav className="ms-auto justify-content-end">
            <Navbar.Text className='d-none d-md-block pe-4'>Hi {auth?.name}!</Navbar.Text>
            {props.sidebarProps.items.map(i => <Nav.Link key={i.title} href={i.navigateTo}>{i.title}</Nav.Link>)}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>

    <div className='layout'>
      <div className='content'>
        <Container>
          <Row>
            <Col style={{ maxWidth: '800px' }}>
              <h2 className='mb-4'>{props.navbarTitle || title}</h2>
              <Outlet />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </div>

    {auth && <Feedback customerId={auth.customerId} />}
  </>;
}

const pathToTitle = (location: any, params: any) => {
  const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);
  const elements = location.pathname.split('/');
  return capitalize(elements.pop());
};
