import React, { useContext, useState } from 'react';
import { Card } from 'react-bootstrap';
import GoogleLoginButton from '../layouts/utils/google-login-button';
import { AuthContext } from '../auth-provider';
import { Link, Navigate } from 'react-router-dom';

function SignupPage() {
  const auth = useContext(AuthContext)!;
  const [isChecked, setIsChecked] = useState({ terms: false, dataSharing: false });

  const handleCheckboxChange = (event: any) => {
    setIsChecked(prevState => ({
      ...prevState,
      [event.target.name]: event.target.checked
    }));
  }

  if (auth) return <Navigate to='/account' />;

  return <div style={{ width:'500px', maxWidth: '100%' }}>
    <Card className='text-center mx-2' style={{ padding: '2rem' }}>
      <h1>Get started with Unspam</h1>
      <p className='py-4'>Sign up to start taking back control of your inbox.</p>
      <div className='pb-5 text-start'>
        <label className='pb-4'>
          <input type='checkbox' name='terms' onChange={handleCheckboxChange} className='me-1' />
          I agree to Unspam.io's <a href='/terms-of-use'>Terms of Use</a> and <a href='/privacy-policy'>Privacy Policy</a>.
        </label>
        <label>
          <input type='checkbox' name='dataSharing' onChange={handleCheckboxChange} className='me-1' />
          I consent to Unspam using OpenAI, a third-party service, to analyze the content of my emails for the purpose of identifying unsolicited messages. OpenAI does <u>not</u> store or train their models with your emails. 
        </label>
      </div>
      <GoogleLoginButton href={'/api/login'} disabled={!(isChecked.terms && isChecked.dataSharing)} />
    </Card>
    <p className='text-center pt-4'>Already have an account? <Link to='/login'>Log in</Link></p>
  </div>;
}

export default SignupPage;
