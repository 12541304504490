import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../auth-provider';
import Footer from './utils/footer';
import Loading from './loading';
import Feedback from './utils/feedback';
import { Container, Nav, Navbar } from 'react-bootstrap';

export default function CardLayout(props: { requireAuth?: boolean, showFeedback?: boolean, skipLoading?: boolean, hideNavMenu?: boolean }) {
  const auth = useContext(AuthContext);

  // Kick the user out if not authenticated
  if (auth === undefined && !props.skipLoading) return <Loading centerAlign />
  if (props.requireAuth && auth === null) return <Navigate to='/' replace={true} />

  return <>
    <Container>
      <Navbar collapseOnSelect expand='md' bg='transparent' variant='dark'>
        <Navbar.Brand href='/account'>
          <h1>Unspam.io</h1>
        </Navbar.Brand>
        {
          !props.hideNavMenu && <>
            <Navbar.Toggle aria-controls='responsive-navbar-nav' />
            <Navbar.Collapse id='responsive-navbar-nav'>
              <Nav className="ms-auto justify-content-end">
                {
                  auth &&
                  <>
                    <Navbar.Text className='d-none d-md-block pe-4'>Hi {auth?.name}!</Navbar.Text>
                    <Nav.Link href={'/account'}>Account</Nav.Link>
                    <Nav.Link href={'/api/logout'}>Logout</Nav.Link>
                  </>
                }
                {
                  auth === null &&
                  <>
                    <Nav.Link href={'/login'}>Login</Nav.Link>
                  </>
                }
              </Nav>
            </Navbar.Collapse>
          </>
        }
      </Navbar>
    </Container>

    <div className='layout'>
      <div className='content center'>
        <Outlet />
      </div>
      <Footer />
    </div>
    {props.showFeedback && <Feedback customerId={auth!.customerId} />}
  </>;
}
