import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function PolicyTermsOfUse() {
  return <Container className='mt-1'>
    <Row>
      <Col>
        <h1>Terms of Use</h1>
        <p><strong>Last updated:  October 27, 2023</strong></p>
        <hr />

        <h2>Welcome to Unspam.io!</h2>
        <p>Please read these <strong>Terms of Use ("Terms")</strong> carefully before using the Unspam.io website and services (collectively, the "Service") provided by Unspam.io ("us", "we", or "our").</p>

        <p>By accessing or using the Service, you agree to be bound by these Terms. If you do not agree to any part of these Terms, you may not access or use the Service.</p>

        <h3>1. Account Registration</h3>
        <p>To access and use the Service, you must register for an account ("<strong>Account</strong>"). By registering for an Account, you represent and warrant that:</p>
        <ul>
          <li>You are at least 18 years old;</li>
          <li>You have the legal capacity to enter into a binding contract;</li>
          <li>You are not a competitor of Unspam.io or using the Service for purposes of monitoring its availability, performance, or functionality;</li>
          <li>You are not a person barred from receiving the Service under the laws of the country in which you reside or from where you use the Service; and</li>
          <li>You will provide and maintain accurate, complete, and up-to-date information as required for your Account.</li>
        </ul>

        <h3>2. Privacy and Data Protection</h3>
        <p>By using the Service, you agree to the collection, use, and disclosure of your personal data in accordance with our <a href="https://unspam.io/privacy-policy"><strong>Privacy Policy</strong></a>. In addition, you acknowledge and agree that we may access, preserve, and disclose your Account information if required to do so by law or in a good faith belief that such access, preservation, or disclosure is reasonably necessary to:</p>
        <ul>
          <li>Comply with legal process;</li>
          <li>Enforce these Terms;</li>
          <li>Respond to claims that any content violates the rights of third parties;</li>
          <li>Respond to your requests for customer service; or</li>
          <li>Protect the rights, property, or personal safety of Unspam.io, its users, and the public.</li>
        </ul>

        <h3>3. Compliance with Laws</h3>
        <p>You represent and warrant that:</p>
        <ul>
          <li>Your use of the Service will comply with all applicable local, state, national, and international laws, rules, and regulations, including but not limited to data protection, privacy, and electronic communications legislation;</li>
          <li>You will not use the Service to send unsolicited or harassing emails;</li>
          <li>You will not use the Service to infringe upon the rights of others, including intellectual property rights;</li>
          <li>You will not use the Service to transmit, store, or process any data that contains viruses or other harmful components; and</li>
          <li>You will not use the Service in any manner that may subject Unspam.io to any legal or regulatory liability.</li>
        </ul>

        <h3>4. Use of Google APIs</h3>
        <p>Unspam.io's use of information received from Google APIs will adhere to <a href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes'>Google API Services User Data Policy</a>, including the Limited Use requirements.</p>

        <h3>5. Fair Use Policy</h3>
        <p>You agree to use the Service in a reasonable and responsible manner, in accordance with our Fair Use Policy. We reserve the right to monitor your use of the Service and take appropriate action, including suspension or termination of your Account, if we determine, in our sole discretion, that you have violated our Fair Use Policy. Factors that may indicate a violation of the Fair Use Policy include, but are not limited to:</p>
        <ul>
          <li>Abnormally high volume of emails being processed through your Account;</li>
          <li>Excessive consumption of system resources;</li>
          <li>Disrupting or degrading the performance of the Service for other users; or</li>
          <li>Using the Service for illegal or abusive purposes.</li>
        </ul>
        <p>We will make reasonable efforts to notify you if your use of the Service is in violation of this Fair Use Policy before taking any action. However, in cases of severe misuse or if immediate action is required to protect the integrity and performance of the Service, we may take action without prior notice.</p>
        <p>We reserve the right to modify the Fair Use Policy at any time, and such modifications will be effective immediately upon posting. It is your responsibility to regularly review the Fair Use Policy for any updates.</p>

        <h3>6. Intellectual Property</h3>
        <p>All intellectual property rights in and to the Service, including any trademarks, service marks, logos, and copyrighted materials, are owned by Unspam.io and its licensors. You are granted a limited, non-exclusive, non-transferable, and non-sublicensable license to access and use the Service solely for your personal or internal business purposes, subject to these Terms.</p>

        <h3>7. Termination</h3>
        <p>We may terminate or suspend your access to the Service at any time, with or without cause or notice, effective immediately. You may terminate your Account at any time by providing us with written notice of your intention to do so. Upon termination, your right to use the Service will immediately cease. All provisions of these Terms which, by their nature, should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>

        <h3>8. Disclaimer of Warranties</h3>
        <p>The Service is provided on an "<strong>as is</strong>" and "<strong>as available</strong>" basis. Unspam.io disclaims all warranties of any kind, whether express or implied, including, without limitation, any warranties of merchantability, fitness for a particular purpose, and non-infringement. Unspam.io does not warrant that the Service will be error-free, secure, or uninterrupted, or that the results obtained through the use of the Service will be accurate or reliable.</p>

        <h3>9. Handling of False Positives</h3>
        <p>We understand that our email filtering service may occasionally classify legitimate emails as spam or unwanted content (false positives). While we strive for accuracy, false positives can occur due to the complexity of email filtering algorithms.</p>
        <p>If you believe that your email has been incorrectly filtered as spam or unwanted content, please promptly report it to Unspam.io through the contact information provided in Section 13. We will investigate such reports and, where appropriate, take steps to improve our filtering accuracy.</p>
        <p>While we make efforts to minimize false positives, we do not guarantee that all false positives can be completely eliminated. By using our service, you acknowledge and accept the possibility of false positives and agree that our service is provided "as is" and "as available," as described in Section 7.</p>

        <h3>10. Limitation of Liability</h3>
        <p>In no event shall Unspam.io or its affiliates, directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages, including, without limitation, loss of profits, data, use, or goodwill, arising out of or in connection with the Service, whether in an action of contract, tort, or otherwise, even if Unspam.io has been advised of the possibility of such damages. Unspam.io's total liability to you for any claim arising out of or in connection with the Service shall not exceed the fees paid by you for the Service during the six (6) month period immediately preceding the event giving rise to the claim.</p>

        <h3>11. Indemnification</h3>
        <p>You agree to indemnify, defend, and hold harmless Unspam.io and its affiliates, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees and costs, arising out of or in connection with your access to or use of the Service, your violation of these Terms, or your infringement of any third party's rights, including intellectual property rights.</p>

        <h3>12. Governing Law and Jurisdiction</h3>
        <p>These Terms shall be governed by and construed in accordance with the laws of the United Kingdom, without regard to its conflict of law principles. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in the United Kingdom.</p>

        <h3>13. Changes to Terms</h3>
        <p>We reserve the right, in our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms.</p>

        <h3>14. Contact Information</h3>
        <p>If you have any questions or concerns about these Terms, please contact us at <a href="mailto:hi@unspam.io"><strong>hi@unspam.io</strong></a>.</p>
        <hr />
      </Col>
    </Row>
  </Container>
}

export default PolicyTermsOfUse;
