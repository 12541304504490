import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { blogPostList } from '../utils/blog-posts';
import { useParams } from "react-router-dom";
import { marked } from 'marked';

function BlogPostPage() {
  const { postId } = useParams();
  const post = blogPostList.find(post => post.id === postId);
  if (!post) return <div className='text-center'>Post not found</div>;
  const html = marked.parse(post.content) as string;

  return <div className='blog-page'>
    <div className='section-hero'>
      <Container>
        <Row className='py-lg-big'>
          <Col>
            <h1>{post.title}</h1>
            <h3 className='my-3'><span className="badge bg-info">Published {post.date}</span></h3>
          </Col>
        </Row>
      </Container>
    </div>

    <Container fluid>
      <Container>
        <Row className='pb-5 mb-5'>
          <Col>

            <div dangerouslySetInnerHTML={{ __html: html }} />

          </Col>
        </Row>
      </Container>
    </Container>

    <Container fluid className='section-callout'>
      <Row className='py-lg-big text-center'>
        <Col>
          <h2>Busy people use Unspam<br />to reduce spam emails from their inbox</h2>
          <p className='mt-4'><Button href='/signup' size='lg'>Regain sanity today</Button></p>
        </Col>
      </Row>
    </Container>
  </div>;
}

export default BlogPostPage;
