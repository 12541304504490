import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { fetchSelfAPI } from './utils/https';

/**
 * Custom subscription statuses, taking into account's Stripe's statuses.
 * https://stripe.com/docs/api/subscriptions/object#subscription_object-status
 * Note: remember to keep handle-web-account.ts Worker in sync
 */
export enum AccountStatus {
  signUpIncomplete = 'sign_up_incomplete', // No Stripe Subscription created yet
  trialing = 'trialing', // In trial
  active = 'active', // Subscription active
  pastDue = 'past_due', // Subscription active but money due
  inactive = 'inactive', // Subscription cancelled, expired, or lapsed rendering it inactive
}

export interface Auth {
  customerId: number;
  name: string;
  email: string;
  status: AccountStatus;
  googleEmailPermissionReceived: boolean;
  spamFilterStatus: string;
  createdAt: Date;
  openAIKeySummary: string | null;
};

// Define the AuthContext
export const AuthContext = React.createContext<Auth | undefined | null>(undefined);

interface Props {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: Props) => {
  const [auth, setAuth] = useState<Auth | undefined | null>(undefined);
  const location = useLocation();

  useEffect(() => {
    async function fetchAccount() {
      try {
        const data = await fetchSelfAPI('/api/web/account');
        if (data.error)
          setAuth(null);
        else
          setAuth({ ...data as Auth, createdAt: new Date(data.createdAt) });
      } catch (error) {
        setAuth(null);
      }
    }

    // fetch account only when auth is undefined
    if (auth === undefined && location.pathname !== '/') {
      fetchAccount();
    }
  }, [auth, location.pathname]);

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
}

/**
 * Redirects the user to the login page with a "loggedout" parameter set to the current URL.
 * Uses `window.location.replace` to avoid creating a new entry in the browser's history.
 */
export const redirectToLogin = () => {
  window.location.replace(`/?loggedout=${window.location.href}`)
  // or history.push('/login') if your Login page is inside the same app
}
