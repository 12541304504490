import React from 'react';
import { Badge, Card } from 'react-bootstrap';

const codeSampleStyle = {
  fontFamily: 'SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
  padding: '1.5rem',
  paddingBottom: '0.5rem',
  background: 'black',
  borderRadius: '1rem',
  marginBottom: '2rem',
};

function SamplesPage() {

  return (
    <>
      <Card className='mb-5'>
        <Card.Header>
          <h3>Email Samples</h3>
          <p style={{ marginBottom: '0' }}>Below are some email templates you can use to test the Unspam filter on your inbox. Note however that if you receive emails from any address that you've sent an email <i>to</i> before, Unspam will ignore it.</p>
        </Card.Header>
        <Card.Body>

          <div style={codeSampleStyle}>
            <p><Badge bg='primary'>DEVELOPMENT SERVICES</Badge></p>
            <p>Hi there</p>
            <p>I want to follow up to see if it still makes sense to talk about how we can help you with cost-effective advantage and flexible team scale up by outsourcing your IT projects.</p>
            <p>So, if it still makes sense to talk, please go ahead and reply to me, I will send you my calendar link for a quick meeting.</p>
            <p>Thank you.</p>
            <p>Regards,</p>
          </div>

          <div style={codeSampleStyle}>
            <p><Badge bg='primary'>LEADGEN SERVICES</Badge></p>
            <p>Hi there</p>
            <p>I left you a couple messages about your customer acquisition strategy.</p>
            <p>We recently worked with CloudCustom, MakeMine, and Parcy to get them new clients using personalized email sequences to book meetings with targeted prospects.</p>
            <p>Do you have 15 mins for a call in the next few days for me to explain in more detail how we can help your business get similar results?</p>
            <p>Thanks,</p>
          </div>

          <div style={codeSampleStyle}>
            <p><Badge bg='primary'>RECRUITMENT SERVICES</Badge></p>
            <p>Hi there</p>
            <p>I hope you are well.</p>
            <p>I wanted to reach out to see how .Net recruitment was going at your company? For a lot of businesses, the end of the month means resignations, and many businesses, unfortunately, lose valuable tech resources.</p>
            <p>Are you looking to replace any outgoing staff at your company? Or equally, are you looking to expand your current team? I am currently working with a large network of .Net professionals across the country.</p>
            <p>Would you be interested in having a look at the profiles I am currently working with? I would love to set up a Teams chat to discuss how I could introduce candidates of a high calibre to your business.</p>
            <p>Let me know when a good time to chat would be.</p>
            <p>Thanks,</p>
          </div>

        </Card.Body>
      </Card>
    </>
  );
}

export default SamplesPage;
