export default function GoogleLoginButton(props: { href: string, disabled?: boolean }) {
  const href = props.disabled ? undefined : props.href;
  const enabledClass = props.disabled ? 'disabled' : 'enabled';
  return <a className={`google-btn ${enabledClass}`} href={href}>
    <div className='google-icon-wrapper'>
      <img className='google-icon' src='/google_logo.svg' alt='Sign in with Google' />
    </div>
    <p className='google-btn-text'><b>Sign in with Google</b></p>
  </a>;
}
