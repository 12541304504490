import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

function RecentlyFilteredDomains(props: { domains: string[] }) {
  const [domains, setDomains] = useState<string[]>(props.domains);

  useEffect(() => {
    setDomains(props.domains);
  }, [props.domains]);

  return (
    <>
      <Card className='mb-4'>
        <Card.Header>
          <h3 style={{ margin: 0 }}>Recently Filtered Domains</h3>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              {domains.slice(0, 10).map((domain: string, index: number) => <div key={index}>{domain}</div>)}
              {domains.length === 0 && <p>No domains filtered yet.</p>}
            </Col>
            <Col>
              {domains.slice(10).map((domain: string, index: number) => <div key={index}>{domain}</div>)}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default RecentlyFilteredDomains;
