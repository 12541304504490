import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { BlogPost, blogPostList } from '../utils/blog-posts';

const BlogPostCard = (post: BlogPost) => {
  const navigate = useNavigate();
  return <div className="card mb-4" onClick={() => navigate(`/blog/${post.id}`)}>
    <div className="card-body">
      <h3 className="card-title">{post.title}</h3>
      <p><span className="badge bg-info">{post.date}</span> - {post.author}</p>
      <p className="card-text">{post.summary}</p>
      <a href={`/blog/${post.id}`}>Read post</a>
    </div>
  </div>;
};

function BlogPage() {
  return <div className='blog-page'>
    <div className='section-hero'>
      <Container>
        <Row className='py-lg-big'>
          <Col>
            <h1>The Unspam Blog</h1>
            <h3 className='my-3'>Sharing the journey of cutting inbox noise, one spam email at a time.</h3>
          </Col>
        </Row>
      </Container>
    </div>

    <Container fluid>
      <Container>
        <Row className='pb-5 mb-5'>
          <Col>

            <div className="d-flex flex-column bd-highlight mb-3">
              {blogPostList.map((post, index) => <BlogPostCard key={index} {...post} />)}
            </div>

          </Col>
        </Row>
      </Container>
    </Container>

    <Container fluid className='section-callout'>
      <Row className='py-lg-big text-center'>
        <Col>
          <h2>Busy people use Unspam<br />to cut the noise in their inbox</h2>
          <p className='mt-4'><Button href='/signup' size='lg'>Start cleaning your inbox today</Button></p>
        </Col>
      </Row>
    </Container>
  </div>;
}

export default BlogPage;
