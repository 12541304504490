/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { fetchSelfAPI } from '../../utils/https';
import Joi from 'joi';

type WhitelistedEmail = {
  WhitelistedEmailId?: number;
  CustomerId: number;
  Email?: string;
  Domain: string;
}

function WhitelistCard() {
  const [whitelist, setWhitelist] = useState<WhitelistedEmail[]>([]);

  useEffect(() => {
    const f = async () => {
      try {
        const whitelist = await fetchSelfAPI('/api/web/whitelists');
        setWhitelist(whitelist as WhitelistedEmail[]);
      } catch (error) {
        console.error('Error: Could not fetch whitelist.', error);
      }
    };
    f();
  }, []);

  const handleEmailWhitelisting = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget as HTMLFormElement;
    const input = form.querySelector('input')!.value;

    if (!input) {
      alert('Please enter a valid email or domain.');
      return;
    }

    try {
      const email = input.includes('@') ? input : null;
      const domain = input.includes('@') ? input.split('@')[1] : input;

      const { error } = Joi.object({
        email: Joi.string().email({ tlds: { allow: false } }).allow(null),
        domain: Joi.string().domain().required(),
      }).validate({ email, domain });
      if (error) return alert(error.message);

      const whitelistEmail = await fetchSelfAPI('/api/web/whitelists', {
        method: 'POST',
        body: JSON.stringify({ email, domain, action: 'create' }),
      });
      const newWhitelist = [...whitelist, whitelistEmail];
      setWhitelist(newWhitelist);
      form.reset();
    } catch (error) {
      console.error('Error: Could not create whitelist entry.', error);
      alert('Error: Could not create whitelist entry.');
    }
  };

  const handleDeleteWhitelisting = async (whitelistedEmailId: number) => {
    try {
      await fetchSelfAPI('/api/web/whitelists', {
        method: 'POST',
        body: JSON.stringify({ whitelistedEmailId, action: 'delete' }),
      });
      const newWhitelist = whitelist.filter(item => item.WhitelistedEmailId !== whitelistedEmailId);
      setWhitelist(newWhitelist);
    } catch (error) {
      console.error('Error: Could not delete whitelist entry.', error);
      alert('Error: Could not delete whitelist entry.');
    }
  };

  return (
    <>
      <Card className='mb-4'>
        <Card.Header>
          <h3 style={{ margin: 0 }}>Whitelisted Domains & Emails</h3>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <p>The following domains and email addresses are skipped during the filtering process.</p>
              {whitelist.length === 0 && <p>No whitelisted domains or emails.</p>}
              <ul>
                {whitelist.map((whitelistEmail, index) =>
                  <li key={index}>
                    {whitelistEmail.Email ? whitelistEmail.Email : '*@' + whitelistEmail.Domain}
                    {' '}<a onClick={() => handleDeleteWhitelisting(whitelistEmail.WhitelistedEmailId!)} className='link-secondary'>(delete)</a>
                  </li>
                )}
              </ul>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <Form onSubmit={handleEmailWhitelisting}>
                <Form.Group className="mb-3">
                  <Form.Label>Add an email or domain to whitelist</Form.Label>
                  <Form.Control type="text" placeholder="E.g. john@example.com or example.com" />
                </Form.Group>
                <Button variant="primary" size="sm" type="submit">Add</Button>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default WhitelistCard;
